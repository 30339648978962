@font-face {
    font-family: "Helvatica Neue";
    src: url(../assets/fonts/HelveticaNeueLight.otf);
    font-weight: 300;
}

@font-face {
    font-family: "Helvatica Neue";
    src: url(../assets/fonts/HelveticaNeueRoman.otf);
    font-weight: 400;
}

@font-face {
    font-family: "Helvatica Neue";
    src: url(../assets/fonts/HelveticaNeueMedium.otf);
    font-weight: 500;
}

@font-face {
    font-family: "Helvatica Neue";
    src: url(../assets/fonts/HelveticaNeueBold.otf);
    font-weight: 700;
}

.btn-white {
    background-color: #fff;
    &:hover {
        background-color: $light;
    }
}

.table-responsive {
    padding-bottom: 10px;
    &::-webkit-scrollbar {
        height: 5px;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: $dark;
    }
    &::-webkit-scrollbar-track {
        background: $light;
        border-radius: 5px;
    }
}

body {
    font-family: "Helvatica Neue";
}

.text-sec2 {
    color: $secondary-base;
}

// Here you can add other styles
.sidebar-brand .sidebar-brand-narrow {
    height: 40px;
}

.sidebar-nav .nav-icon {
    fill: none;
}

.header .dropdown-toggle::after {
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
    vertical-align: 0.155em;
    margin-left: 0.255em;
}

.header .notification-toggler {
    border-left: 1px solid $secondary;
    border-right: 1px solid $secondary;
    margin-left: 15px;
    &>a.nav-link {
        position: relative;
        margin: 0 6px 0 0;
        padding-bottom: 0;
    }
}

.page-title {
    margin-bottom: 24px;
    h1 {
        font-size: 24px;
        font-weight: 500;
        color: $dark;
        margin: 0;
    }
}

.vertical-border {
    vertical-align: middle;
    height: 28px;
    width: 1px;
    margin: 0 15px;
    display: inline-block;
    background: $secondary;
}

.btn svg {
    vertical-align: -3px;
}

.custom-card {
    .card-body {
        padding: 20px 20px;
    }
    .card-title {
        font-size: 40px;
        font-weight: 500;
        color: $dark;
        margin-bottom: 15px;
    }
    .icon-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
            height: 35px;
        }
    }
    .card-text {
        color: $secondary-base;
        font-size: 14px;
        margin: 0;
    }
}

.form-label {
    color: $dark;
}

.form-control {
    height: 41px;
    color: $dark;
}

.form-text {
    font-size: 12px;
    color: $secondary-base;
}

.custom_modal {
    .modal-header,
    .modal-body {
        background-color: #F7FAFB;
    }
    .modal-body {
        padding: 20px;
    }
    .up-content {
        padding-bottom: 15px;
        h2 {
            font-size: 24px;
            font-weight: 500;
            color: $dark;
            margin: 12px 0;
        }
        p {
            max-width: 265px;
            font-size: 14px;
            color: $dark;
            margin: auto;
        }
    }
    .modal-footer {
        padding: 20px;
    }
    .bottom_btns {
        width: 100%;
        margin-top: 35px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        button {
            width: 48%;
        }
    }
}

.rti--container {
    border-radius: 0.375rem !important;
    border: 1px solid var(--cui-input-border-color, #b1b7c1) !important;
    color: $dark;
    // padding: 0.3rem 0.75rem 0rem !important;
    .rti--tag {
        background-color: #F0F4F7;
        border-color: #F0F4F7;
        border-radius: 3px;
        color: $dark;
        font-size: 14px;
        text-transform: capitalize;
        font-family: "Helvatica Neue";
        // padding: 0 6px;
        a {
            text-decoration: none;
            color: $dark;
            font-size: 20px;
            font-weight: 400;
            vertical-align: -1px;
        }
    }
}

.card_toolbar {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $secondary;
    @media (max-width: 767px) {
        flex-direction: column-reverse;
        .btns {
            margin: 0 0 16px auto;
        }
    }
}

.react-tabs .react-tabs__tab-list {
    border-bottom: 0;
    margin: 0;
    .react-tabs__tab {
        border: none;
        border-radius: 0;
        padding-bottom: 15px;
        color: rgba($color: $dark, $alpha: 0.6);
        &::after {
            content: none;
        }
        &.react-tabs__tab--selected {
            border-bottom: 4px solid $primary !important;
            color: $primary;
        }
    }
}

.folder-search-field {
    border-right: 0;
}

// Folder Popup Checkbox
.folder_checkbox {
    position: relative;
    margin-bottom: 10px;
    input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
    label {
        background: $light;
        border: 1px solid $secondary;
        border-radius: 5px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $dark;
        padding: 9px 15px;
        transition: .3s all ease;
        margin: 0;
        svg path {
            fill: $primary;
            transition: .3s all ease;
        }
    }
    input:checked+label {
        background: $primary;
        color: #fff;
        transition: .3s all ease;
        svg path {
            fill: #fff;
            transition: .3s all ease;
        }
    }
}

// Screen Grid
.screen_col {
    .img {
        position: relative;
        .dropdown {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
    .text {
        padding: 16px 16px;
    }
    h3 {
        font-size: 18px;
        color: $dark;
    }
    p {
        font-size: 14px;
    }
    .border-right {
        border-right: 1px solid $secondary;
    }
}

.selectable_card {
    position: relative;
    &>input {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 3;
        transform: scale(1.5);
    }
    &>label {
        cursor: pointer;
    }
}

// Screen Details
.screen_visuals {
    .thumbnail {
        position: relative;
        button {
            position: absolute;
            left: 22px;
            bottom: 22px;
        }
    }
    h3 {
        font-size: 18px;
    }
}

.screen_info {
    .status {
        border-bottom: 1px solid $secondary;
        padding-bottom: 12px;
        p {
            margin-bottom: 12px;
        }
        .border-right {
            border-right: 1px solid $secondary;
        }
    }
    .qrbox {
        max-width: max-content;
    }
    h3 {
        font-size: 18px;
    }
}